import i18n from '@/plugins/i18n/index'

export const PaymentType = [i18n.global.t('accounts.search.freight'), i18n.global.t('accounts.search.pod'), i18n.global.t('accounts.search.pr')]

export const exportOptions = [
  { title: i18n.global.t('accounts.search.excel'), value: 'excel' },
  { title: i18n.global.t('accounts.search.pdf'), value: 'pdf' },
]

export const capacityType = [i18n.global.t('accounts.search.tons')]

export const mcBookType = [i18n.global.t('accounts.search.yashwantrao')]

// Define Type
export const accountTypes = {
  truckerPayable: 'truckerPayable',
  loaderPayable: 'loaderPayable',
  commonData: 'commonData',
  mcBook: 'mcBook',
  cashLedger: 'cashLedger',
}

export const customDaysTypes = [
  { value: 'today', title: 'Today' },
  { value: 'last30', title: 'Last 30 Days' },
  { value: 'last90', title: 'Last 90 Days' },
  { value: 'last180', title: 'Last 180 Days' },
  { value: 'custom', title: 'Custom' },
]

export const centralizedTableOptions = reactive({
  page: 1,
  limit: 10,
  sortField: '',
  sortOrder: '',
  search: false,
})

export const centralizedPaginationPage = [
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: 100, title: '100' },
]

// Transaction Report
export const transactionHeader = [
  { title: 'ID', key: 'ledgerId', sortable: false },
  { title: 'Transaction Date', key: 'date', sortable: false },
  { title: 'CNF ID', key: 'confirmationId', sortable: false },
  { title: 'User Type', key: 'userType', sortable: false },
  { title: 'Business Name', key: 'businessName', sortable: false },
  { title: 'Description', key: 'description', sortable: false },
  { title: 'Payment', key: 'amountType', sortable: false },
  { title: 'Tran. Type', key: 'paymentMethod', sortable: false },
  { title: 'Reference', key: 'reference', sortable: false },
  { title: 'Receivable', key: 'receivable', sortable: false },
  { title: 'Received', key: 'received', sortable: false },
  { title: 'Payable', key: 'payable', sortable: false },
  { title: 'Paid', key: 'paid', sortable: false },
]

// Trucker  Ledger Report
export const truckerHeader = [
  { title: 'ID', key: 'id', sortable: false },
  { title: 'Business Name', key: 'companyName', sortable: false },
  { title: 'Trading Name', key: 'tradingName', sortable: false },
  { title: 'Receivable (A)', key: 'receivable', sortable: false },
  { title: 'Received (B)', key: 'received', sortable: false },
  { title: 'Payable (C)', key: 'payable', sortable: false },
  { title: 'Paid (D)', key: 'paid', sortable: false },
  { title: 'Balance (A-C)', key: 'balance', sortable: false },
  { title: 'Actions', key: 'actions', sortable: false },
]

// Loader Ledger Report
export const loaderHeader = [
  { title: 'ID', key: 'id', sortable: false },
  { title: 'Business Name', key: 'companyName', sortable: false },
  { title: 'Trading Name', key: 'tradingName', sortable: false },
  { title: 'Receivable (A)', key: 'receivable', sortable: false },
  { title: 'Received (B)', key: 'received', sortable: false },
  { title: 'Payable (C)', key: 'payable', sortable: false },
  { title: 'Paid (D)', key: 'paid', sortable: false },
  { title: 'Balance (A-C)', key: 'balance', sortable: false },
  { title: 'Actions', key: 'actions', sortable: false },
]

// MC Book Data
export const mcBookHeader = [
  { title: 'ID', key: 'id', sortable: false },
  { title: 'MC Book Name', key: 'mcBookName', sortable: false },
  { title: 'Associated With', key: 'associatedWith', sortable: false },
  { title: 'Business Name', key: 'businessName', sortable: false },
  { title: 'Primary Mobile No.', key: 'mobile', sortable: false },
  { title: 'Receivable (A)', key: 'receivable', sortable: false },
  { title: 'Received (B)', key: 'received', sortable: false },
  { title: 'Payable (C)', key: 'payable', sortable: false },
  { title: 'Paid (D)', key: 'paid', sortable: false },
  { title: 'Balance (A-C)', key: 'balance', sortable: false },
  { title: 'Actions', key: 'actions', sortable: false },
]

export const cashLedgerHeader = [
  { title: 'ID', key: 'userId', sortable: false },
  { title: 'Transaction Date', key: 'date', sortable: false },
  { title: 'CNF ID', key: 'confirmationId', sortable: false },
  { title: 'Description', key: 'description', sortable: false },
  { title: 'Reference', key: 'reference', sortable: false },
  { title: 'Received', key: 'received', sortable: false },
  { title: 'Paid', key: 'paid', sortable: false },
]

export const bankHeader = [
  { title: 'ID', key: 'userId', sortable: false },
  { title: 'Transaction Date', key: 'date', sortable: false },
  { title: 'CNF ID', key: 'confirmationId', sortable: false },
  { title: 'Description', key: 'description', sortable: false },
  { title: 'Reference', key: 'reference', sortable: false },
  { title: 'Received', key: 'received', sortable: false },
  { title: 'Paid', key: 'paid', sortable: false },
]

export const balanceHeader = [
  { title: 'Receivable', value: 'receivable' },
]

export const balanceData = [
  { receivable: '746' },
]
